import { Counter } from './features/counter/Counter';
import './App.css';
import '../src/assets/style.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-grid.css';
import StoresListContainer from './features/stores/list/StoresListContainer';
import StoreReportContainer from './features/stores/report/StoreReportContainer';
import LoginContainer from './features/login/LoginContainer';
import Authorization from './utils/authorization';
import StoreManagementContainer from './features/stores/management/StoreManagementContainer';
import StoreCreationContainer from './features/stores/management/creation/StoreCreationContainer';
import StoreCustomizationContainer from './features/customization/StoreCustomizationContainer';

function App() {
  const isLoggedIn = localStorage.getItem('token') == undefined;
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={ isLoggedIn ? <LoginContainer /> : <Authorization element={<StoresListContainer />}/>}/>
          <Route path='/counter' element={<Authorization element={<Counter/>}/>}/>
          <Route path='/stores' element={<Authorization element={<StoresListContainer />}/>}/>
          <Route path='/report/:storeId' element={<Authorization element={<StoreReportContainer />}/>}/>
          <Route path='/login' element={<LoginContainer />} />
          <Route path='/management/:storeId' element={<Authorization element={<StoreManagementContainer/>}/>} />
          <Route path='/create' element={<Authorization element={<StoreCreationContainer />}/>}/>
          <Route path='/customization/:storeId' element={<StoreCustomizationContainer />}/>
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;
