import { useState, useEffect } from "react";
import { Layout } from '../../global/Layout';
import { useParams } from "react-router-dom";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import StoreSyncForm from './StoreSyncForm';
import { Store } from '../../../types';
import { getAllStores, getStoreById } from "../../../lib/admin";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import StoreHydrogenForm from "./StoreHydrogenForm";
import StoreRetailSystemForm from "./StoreRetailSystemForm";
import StoreShopifyForm from "./StoreShopifyForm";
import StoreShopifySyncForm from "./StoreShopifySyncForm";
import NoImage from '../../../assets/NoImage.jpg';
import StoreCSVForm from "./StoreCSVForm";
import StoreSyncFormType from "./StoreSyncTypeForm";
import StoreShopifySettings from "./StoreShopifySettings";
import { SpinnerCircularFixed } from "../../../assets/spinner/SpinnerCircularFixed";
import StoreCustomizationContainer from "../../customization/StoreCustomizationContainer";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function StoreManagementContainer() {
  const { storeId } = useParams<{ storeId: string }>();
  const [store, setStore] = useState<undefined | Store>(undefined);
  const [value, setValue] = useState(2);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [hasBeenUpdated, setHasBeenUpdated] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [pendingTabIndex, setPendingTabIndex] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (hasUnsavedChanges) {
      setConfirmDialogOpen(true);
      setPendingTabIndex(newValue);
    } else {
      setValue(newValue);
    }
  };

  const handleConfirmDialogClose = (confirmed: boolean) => {
    setConfirmDialogOpen(false);
    if (confirmed) {
      setHasUnsavedChanges(false);
      setValue(pendingTabIndex);
    }
  }

  const updateStore = async () => {
    if (storeId == undefined) {
      setStore(undefined);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    try {
      const newStore = await getStoreById(parseInt(storeId));
      setStore(newStore);
    } catch (error) {
      console.error("Error fetching store:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (hasBeenUpdated || firstLoad) {
      updateStore();
      setHasBeenUpdated(false);
      setFirstLoad(false);
    }
  }, [store, hasBeenUpdated]);

  useEffect(() => {
    if (storeId !== "null" && storeId !== undefined && storeId.length > 0) {
      updateStore();
    } else {
      setIsLoading(false);
    }
  }, [storeId]);

  const handleGoBack = () => {
    window.location.href = '/stores';
  };

  const getLogo: () => string = () => {
    if (store != undefined) {
      if (store.logo === null || store.logo === "" || store.logo === undefined) {
        return NoImage;
      } else {
        const image = new Image();
        image.src = store.logo;

        if (image.width > 0 && image.height > 0) {
          return store.logo;
        } else {
          return NoImage;
        }
      }
    } else {
      return NoImage;
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout>
        {localStorage.getItem('role') !== 'admin' && localStorage.getItem('role') !== 'sync_admin'
          ?
          <div className="editStores">
            <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', borderRadius: '15px' }}>
              <div className="leftVertical">
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                  value={value}
                  onChange={handleChange}
                >
                  <Tab className="BackTab" label="Back" onClick={handleGoBack} {...a11yProps(0)} />
                  <div className="logo-title-store" {...a11yProps(1)}  >
                    <div className="one-col storeLogo">
                      <img src={getLogo()} alt={`logo-${store?.friendlyName}`} id="logo-store" className="logo-store-edit-section" />
                    </div>
                    <div className="title-site">
                      <h6>{store?.friendlyName}</h6>
                    </div>
                  </div>
                </Tabs>
              </div>
              <div className="dataPanel">
                {isLoading ? (
                  <div className="loading-div">
                    <SpinnerCircularFixed />
                  </div>
                ) : (
                  store ? (
                    <StoreCustomizationContainer
                      storeId={store.id.toString()}
                    />
                  ) : (
                    <p>Invalid store</p>
                  )
                )}
              </div>
            </Box>
          </div>
          :
          <div className="editStores">
            <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', borderRadius: '15px' }}>
              <div className="leftVertical">
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                  value={value}
                  onChange={handleChange}
                >
                  <Tab className="BackTab" label="Back" onClick={handleGoBack} {...a11yProps(0)} />

                  <div className="logo-title-store" {...a11yProps(1)}  >
                    <div className="one-col storeLogo">
                      <img src={getLogo()} alt={`logo-${store?.friendlyName}`} id="logo-store" className="logo-store-edit-section" />
                    </div>
                    <div className="title-site">
                      <h6>{store?.friendlyName}</h6>
                    </div>
                  </div>

                  <Tab className="SyncTab" label="Sync Status" {...a11yProps(2)} />
                  <Tab className="SyncTypeTab" label="Sync Type" {...a11yProps(3)} />
                  <Tab className="RetailSystemTab" label="RetailSystem" {...a11yProps(4)} />
                  <Tab className="ShopifyTab" label="Shopify" {...a11yProps(5)} />
                  <Tab className="HydrogenTab" label="Shopify Hydrogen" {...a11yProps(6)} />
                  <Tab className="SyncAppTab" label="Shopify Sync App" {...a11yProps(7)} />
                  <Tab className="CSVTab" label="CSV" {...a11yProps(8)} />
                  <Tab className="SettingsTab" label="Settings" {...a11yProps(9)} />
                  <Tab className="CustomizationToolTab" label="Customization" {...a11yProps(10)} />
                </Tabs>
              </div>
              <div className="dataPanel">
                <TabPanel value={value} index={2}>
                  {isLoading ? (
                    <div className="loading-div">
                      <SpinnerCircularFixed />
                    </div>
                  ) : (
                    store ? (
                      <div>
                        <StoreSyncForm
                          store={store}
                          hasBeenUpdated={hasBeenUpdated}
                          hasUnsavedChanges={hasUnsavedChanges}
                          setHasBeenUpdated={setHasBeenUpdated}
                          setHasUnsavedChanges={setHasUnsavedChanges} />
                      </div>
                    ) : (
                      <p>Invalid store</p>
                    )
                  )}
                </TabPanel>
                <TabPanel value={value} index={3}>
                  {isLoading ? (
                    <div className="loading-div">
                      <SpinnerCircularFixed />
                    </div>
                  ) : (
                    store ? (
                      <div>
                        <StoreSyncFormType
                          store={store}
                          hasBeenUpdated={hasBeenUpdated}
                          hasUnsavedChanges={hasUnsavedChanges}
                          setHasBeenUpdated={setHasBeenUpdated}
                          setHasUnsavedChanges={setHasUnsavedChanges} />
                      </div>
                    ) : (
                      <p>Invalid store</p>
                    )
                  )}
                </TabPanel>
                <TabPanel value={value} index={4}>
                  {isLoading ? (
                    <div className="loading-div">
                      <SpinnerCircularFixed />
                    </div>
                  ) : (
                    store ? (
                      <div>
                        <StoreRetailSystemForm
                          store={store}
                          hasBeenUpdated={hasBeenUpdated}
                          hasUnsavedChanges={hasUnsavedChanges}
                          setHasBeenUpdated={setHasBeenUpdated}
                          setHasUnsavedChanges={setHasUnsavedChanges} />
                      </div>
                    ) : (
                      <p>Invalid store</p>
                    )
                  )}
                </TabPanel>
                <TabPanel value={value} index={5}>
                  {isLoading ? (
                    <div className="loading-div">
                      <SpinnerCircularFixed />
                    </div>
                  ) : (
                    store ? (
                      <div>
                        <StoreShopifyForm
                          store={store}
                          hasBeenUpdated={hasBeenUpdated}
                          hasUnsavedChanges={hasUnsavedChanges}
                          setHasBeenUpdated={setHasBeenUpdated}
                          setHasUnsavedChanges={setHasUnsavedChanges} />
                      </div>
                    ) : (
                      <p>Invalid store</p>
                    )
                  )}
                </TabPanel>
                <TabPanel value={value} index={6}>
                  {isLoading ? (
                    <div className="loading-div">
                      <SpinnerCircularFixed />
                    </div>
                  ) : (
                    store ? (
                      <div>
                        <StoreHydrogenForm
                          store={store}
                          hasBeenUpdated={hasBeenUpdated}
                          hasUnsavedChanges={hasUnsavedChanges}
                          setHasBeenUpdated={setHasBeenUpdated}
                          setHasUnsavedChanges={setHasUnsavedChanges} />
                      </div>
                    ) : (
                      <p>Invalid store</p>
                    )
                  )}
                </TabPanel>
                <TabPanel value={value} index={7}>
                  {isLoading ? (
                    <div className="loading-div">
                      <SpinnerCircularFixed />
                    </div>
                  ) : (
                    store ? (
                      <div>
                        <StoreShopifySyncForm
                          store={store}
                          hasBeenUpdated={hasBeenUpdated}
                          hasUnsavedChanges={hasUnsavedChanges}
                          setHasBeenUpdated={setHasBeenUpdated}
                          setHasUnsavedChanges={setHasUnsavedChanges} />
                      </div>
                    ) : (
                      <p>Invalid store</p>
                    )
                  )}
                </TabPanel>
                <TabPanel value={value} index={8}>
                  {isLoading ? (
                    <div className="loading-div">
                      <SpinnerCircularFixed />
                    </div>
                  ) : (
                    store ? (
                      <div>
                        <StoreCSVForm
                          store={store}
                          hasBeenUpdated={hasBeenUpdated}
                          hasUnsavedChanges={hasUnsavedChanges}
                          setHasBeenUpdated={setHasBeenUpdated}
                          setHasUnsavedChanges={setHasUnsavedChanges} />
                      </div>
                    ) : (
                      <p>Invalid store</p>
                    )
                  )}
                </TabPanel>
                <TabPanel value={value} index={9}>
                  {store ? (
                    <div>
                      <StoreShopifySettings
                        store={store}
                        hasBeenUpdated={hasBeenUpdated}
                        hasUnsavedChanges={hasUnsavedChanges}
                        setHasBeenUpdated={setHasBeenUpdated}
                        setHasUnsavedChanges={setHasUnsavedChanges} />
                    </div>
                  ) : (
                    <p>Invalid store</p>
                  )}
                </TabPanel>
                <TabPanel value={value} index={10}>
                  {store ? (
                    <div>
                      <StoreCustomizationContainer
                        storeId={store.id.toString()}
                      />
                    </div>
                  ) : (
                    <p>Invalid store</p>
                  )}
                </TabPanel>
              </div>
            </Box>
          </div>
        }
      </Layout >
      <Dialog
        open={confirmDialogOpen}
        onClose={() => handleConfirmDialogClose(false)}
        aria-labelledby="confirm-unsaved-changes-dialog-title"
        aria-describedby="confirm-unsaved-changes-dialog-description"
      >
        <DialogTitle id="confirm-unsaved-changes-dialog-title">Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-unsaved-changes-dialog-description">
            You have unsaved changes. Are you sure you want to leave without saving?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmDialogClose(false)} color="primary">
            Keep editing
          </Button>
          <Button onClick={() => handleConfirmDialogClose(true)} color="primary" autoFocus>
            Leave
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}