import React, { useEffect } from "react";

interface NotificationProps {
    message: string | null;
    type: "success" | "error";
    onClose: () => void;
}

const NotificationHOC: React.FC<NotificationProps> = ({ message, type, onClose }) => {
    useEffect(() => {
        if (message) {
            const timer = setTimeout(onClose, 3000);
            return () => clearTimeout(timer);
        }
    }, [message, onClose]);

    if (!message) return null;

    return (
        <div className={`notification ${type}`}>
            {message}
        </div>
    );
};

export default NotificationHOC;