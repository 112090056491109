import React, { useEffect, useState } from "react";
import TabContainerHOC from "./hocs/TabContainerHOC";
import DemoImage from '../../assets/customization/demo.png';

interface Category {
    src: string;
    title: string;
    subtitle: string;
    text: string;
    url: string;
}

//mock
const mockCategories = {
    category1: {
        src: '',
        title: '',
        subtitle: '',
        text: '',
        url: ''
    },
    category2: {
        src: '',
        title: '',
        subtitle: '',
        text: '',
        url: ''
    }
}
//--

export default function CentralCategoriesPage() {
    const [category1, setCategory1] = useState<Category>(mockCategories.category1);
    const [category2, setCategory2] = useState<Category>(mockCategories.category2);
    const [calling, setCalling] = useState<boolean>(true);

    useEffect(() => {
        //TODO: call api
        //mock
        setTimeout(() => {
            setCategory1(mockCategories.category1);
            setCategory2(mockCategories.category2);
            setCalling(false);
        }, 1500);
        //--
    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, id: string, isImg: boolean = false): void => {
        let img: string | undefined = undefined;
        if (isImg) {
            img = 'https://bigfurnitureshow.com/assets/missingImage.ea9d6c9f.jpg'
            //TODO: send to shopify
        }
        switch (id) {
            case "1":
                if (img) setCategory1({ ...category1, src: img })
                else setCategory1({ ...category1, [e.target.id]: e.target.value })
                break;
            case "2":
                if (img) setCategory2({ ...category2, src: img })
                else setCategory2({ ...category2, [e.target.id]: e.target.value })
                break;
        }
    }

    const send = (): void => {
        setCalling(true);
        //mock
        setTimeout(() => {
            const body = {
                category1,
                category2
            }
            console.log('SEND TO API: ', body)
            setCalling(false);
            alert('SUCCESSFUL SAVE (TODO: improve)');
        }, 700)
        //--
    }

    return (
        <TabContainerHOC commingSoon title="2 Categories Section" description={<Description />} containerClass="central-categories" loading={calling}>
            <div className="row mt-5 ">
                <Category category={category1} containerClass="col-left" id="1" handleChange={handleChange} />
                <Category category={category2} containerClass="col-right pl-35 bl-1" id="2" handleChange={handleChange} />
            </div>

            <div className="navigationFooter mt-5">
                <a className="backBtn">BACK</a>
                <span className="saveBtn" onClick={send}>SAVE</span>
            </div>
        </TabContainerHOC>
    )
}

const Description = (): JSX.Element => (
    <ul>
        <li>Add the 2 Images, Title, Subtitle, Button text and Link.</li>
    </ul>
)

interface CategoryProps {
    containerClass: string;
    category: Category;
    id: string;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>, id: string, isImg?: boolean) => void;
}

const Category: React.FC<CategoryProps> = ({ containerClass, category, id, handleChange }) => {
    return (
        <div className={`col-sm ${containerClass}`}>
            <h2>Add Image {id}, Title, Subtitle, Button.</h2>
            <div className="headerCategories mt-4">
                <div className="greeNumber">
                    <p>{id}</p>
                </div>
                <div className="headerDesciption">
                    <p><b>Image for Desktop - Mobile</b></p>
                    <p>1300x 1000 pix. or more.<br /> Visible area: Aspect Ratio 4:3
                    </p>
                </div>
            </div>
            <div className="container up mt-2">
                <div className="avatar-upload">
                    <div className="avatar-edit">
                        <input onChange={(e) => handleChange(e, id, true)} type='file' id={`imageUpload${id}`} accept=".png, .jpg, .jpeg" />
                        <label htmlFor={`imageUpload${id}`}></label>
                    </div>
                    <div className="avatar-preview">
                        {category.src !== '' ? <div id="imagePreview" style={{ backgroundImage: `url(${category.src})`, backgroundSize: 'cover' }} />
                            : <div id="imagePreview" style={{ backgroundImage: `url(${DemoImage})` }} />}
                    </div>
                </div>
                <h4>Upload Photo</h4>
            </div>
            <p className="mt-4"><b>Add Title</b><span className="esl">Recommended 40 characters </span></p>
            <input id="title" onChange={(e) => handleChange(e, id)} type="text" className="input-round form-control mt-2" placeholder="Type text." />
            <p className="mt-3"><b>Add Subtitle</b><span className="esl">Recommended 50 characters </span></p>
            <input id="subtitle" onChange={(e) => handleChange(e, id)} type="text" className="input-round form-control mt-2" placeholder="Type text." />
            <p className="mt-3"><b>Text of the button</b></p>
            <input id="text" onChange={(e) => handleChange(e, id)} type="text" className="input-round form-control mt-2 text-center" placeholder="EXAMPLE: LIVING ROOM" />
            <p className="mt-3">Insert link</p>
            <input id="url" onChange={(e) => handleChange(e, id)} type="text" className="input-round form-control mt-2 link" placeholder="https://" />
        </div>
    )
}