import { useCallback, useEffect, useState } from "react";
import { Store } from "../../../types";
import NoImage from '../../../assets/NoImage.jpg';
import PlanIcon from "../../../assets/plan/PlanIcon";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import EditIconNew from "../../../assets/EditIconNew";
import formatDateToLocal from "../../../utils/formatDateToLocal";
import Warning from "../../../assets/warning";
import { enableStore } from "../../../lib/admin";
import Open from "../../../assets/Open";

type Props =
    {
        store: Store,
    }

const planNames: { [key: string]: string } = {
    '0': 'Unknown',
    '1': 'Bronze',
    '2': 'Silver',
    '3': 'Gold',
    '4': 'Platinum',
    '5': 'Kiosk',
    '6': 'Brochure'
};

export default function StoreCardHeader({ store }: Props) {
    const [enabled, setEnabled] = useState(store.enabled);
    const [error, setError] = useState<string | null>(null);
    const [logoUrl, setLogoUrl] = useState<string>(NoImage);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const loadLogo = useCallback(() => {
        setIsLoading(true);
        setLogoUrl(NoImage);

        if (store.logo) {
            const img = new Image();
            img.onload = () => {
                setLogoUrl(store.logo);
                setIsLoading(false);
            };
            img.onerror = () => {
                console.error(`Failed to load logo for ${store.friendlyName}`);
                setLogoUrl(NoImage);
                setIsLoading(false);
            };
            img.src = store.logo;
        } else {
            setIsLoading(false);
        }
    }, [store.logo]);

    useEffect(() => {
        loadLogo();
    }, [loadLogo]);

    useEffect(() => {
        setEnabled(store.enabled);
    }, [store.enabled]);

    const planName = planNames[store.storePlan] || 'Unknown';

    const navigate = useNavigate();

    const getStoreUrl: () => string = () => {
        if (store.isMultiStore) return `https://${store.dns}.com`;
        return `https://${store.dns}`;
    };

    const getStoreAdminUrl: () => string = () => {
        return `https://${store.storeUrl}/admin`;
    };

    const manageStore = () => {
        navigate(`/management/${store.id}`, { replace: true });
    }

    const openDeployDevelopmentSite = () => {
        const { deployDevelopmentSite } = store;
        if (deployDevelopmentSite) {
            window.open(deployDevelopmentSite, '_blank');
        }
    }

    const status = store.liveState == 1 ? 'In Progress' : store.liveState == 2 ? 'Live' : 'Offline';

    const syncType = store.developmentPhase == 2 ? 'v2' : store.developmentPhase == 3 ? 'v2 beta' : '';

    const handleToggle = async () => {
        const newEnabledState = !enabled;
        try {
            await enableStore(store.id, newEnabledState);
            setEnabled(newEnabledState);
            setError(null);
        } catch (error) {
            setError("Failed to enable the store. <br>Please try again later.");
            console.error("Failed to update store status:", error);
        }
    };

    return (
        <>
            {error && (
                <div className="error-popup-overlay">
                    <div className="error-message-popup">
                        <p dangerouslySetInnerHTML={{ __html: error }}></p>
                        <button onClick={() => setError(null)}>Close</button>
                    </div>
                </div>
            )}
            <div className="basic-dates-store topArea row">
                <div className="col-s3 storeLogoContainer col-2">
                    <div className="one-col storeLogo">
                        {isLoading ? (
                            <img src={NoImage} alt={`logo-${store.friendlyName}`} id="logo-store" />
                        ) : (
                            <img src={logoUrl} alt={`logo-${store.friendlyName}`} id="logo-store" />
                        )}
                        <div className={`storeStatus${store.liveState == 1 ? ' storeDevelopment' : store.liveState == 2 ? ' storeActive' : ' storeOff'}`} data-tooltip={status}></div>
                        {
                            (localStorage.getItem('role') === 'admin' || localStorage.getItem('role') === 'sync_admin') && (store.developmentPhase == 2 || store.developmentPhase == 3) && (
                                <div className="ribbon">
                                    <span className={syncType === 'v2 beta' ? 'v2-beta' : syncType}>{syncType}</span>
                                </div>
                            )
                        }
                    </div>
                    {
                        (localStorage.getItem('role') === 'admin' || localStorage.getItem('role') === 'sync_admin') &&
                        <div className="card-enable-store">
                            <label>
                                Disabled
                                <input
                                    type="checkbox"
                                    checked={enabled}
                                    onChange={handleToggle}
                                />
                                Enabled
                            </label>
                        </div>
                    }
                    <p></p>
                </div>
                <div className="col-s10 datesStoreContainer col-4">
                    <div className="websiteContainer">
                        <p className="title-type">
                            <span>
                                <a href={getStoreAdminUrl()} target="blank">
                                    Shopify admin
                                </a>
                            </span>
                        </p>
                    </div>
                    <div className="storeName">
                        <h3 className="title-storeName">
                            {store.dns ?
                                <a href={getStoreUrl()} target="blank" style={{ textDecoration: 'none' }}>
                                    {store.friendlyName}
                                </a>
                                : <span>{store.friendlyName}</span>}
                        </h3>
                        {
                            (localStorage.getItem('role') === 'admin' || localStorage.getItem('role') === 'sync_admin') && store.deployDevelopmentSite !== null && (
                                <div className="openDeploySite">
                                    <button data-tooltip="Deployment Site" onClick={openDeployDevelopmentSite}><Open></Open></button>
                                </div>
                            )
                        }
                    </div>
                    <p className="store-plan">
                        <span className="store-plan-span">
                            <PlanIcon type={planName} />
                        </span>
                        <span className={`store-plan-${planName}`}>
                            {planName}
                        </span>
                    </p>
                </div>

                <div className="last-refresh-time-container-small col-6">
                    <div className="last-refresh-time-div row ">
                        <div className="col-10 last-refresh-time-div" data-tooltip-content="(UTC)">
                            <div className="last-updated-div">
                                <p className={'last-refresh-time-div'}>
                                    {"Last Updated: "}
                                </p>
                                {store.nextSync && store.lastUpdate && Date.parse(store.lastUpdate) > Date.parse(store.nextSync) && Date.now() > Date.parse(store.nextSync) && (
                                    <Tooltip title="There seems to be an issue with the scheduled sync">
                                        <div className="error-auto-sync">
                                            <Warning />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                            <Tooltip title={localStorage.getItem('time-zone') === 'active' ? 'Local' : 'UTC'}>
                                <span className="last-refresh-time-span">
                                    {localStorage.getItem('time-zone') === 'active'
                                        ? (store.lastUpdate
                                            ? `${formatDateToLocal(store.lastUpdate).replace(/T/, ' ').replace(/\..+/, '')}`
                                            : 'Not Available'
                                        )
                                        : (store.lastUpdate
                                            ? `${store.lastUpdate.replace(/T/, ' ').replace(/\..+/, '')}`
                                            : 'Not Available'
                                        )
                                    }
                                </span>
                            </Tooltip>
                        </div>

                    </div>
                    <div className="last-refresh-time-div row ">
                        <div className="col-10 last-refresh-time-div" data-tooltip-content="(UTC)">
                            <div className={`pending-scheduled-div`}>
                                {
                                    store.nextSync == null && (localStorage.getItem('role') === 'admin' || localStorage.getItem('role') === 'sync_admin') && (
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                manageStore();
                                            }}
                                            style={{ textDecoration: 'none' }}
                                            className="scheduled-pending"
                                        >
                                            Pending scheduled sync ➔
                                        </a>
                                    )
                                }
                                {
                                    store.nextSync !== null && (localStorage.getItem('role') === 'admin' || localStorage.getItem('role') === 'sync_admin') && (
                                        <div className="next-sync-div">
                                            <p className="title">Next:</p>
                                            <Tooltip title={localStorage.getItem('time-zone') === 'active' ? 'Local' : 'UTC'}>
                                                <span className="last-refresh-time-span">
                                                    {localStorage.getItem('time-zone') === 'active'
                                                        ? (store.nextSync
                                                            ? `${formatDateToLocal(store.nextSync).replace(/T/, ' ').replace(/\..+/, '')}`
                                                            : 'Not Available'
                                                        )
                                                        : (store.nextSync
                                                            ? `${store.nextSync.replace(/T/, ' ').replace(/\..+/, '')}`
                                                            : 'Not Available'
                                                        )
                                                    }
                                                </span>
                                            </Tooltip>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="sync-info-container">
                                <div className='user-info-div userRow'>
                                    <p className='user-title'>
                                        {store.email === 'scheduled' ? (
                                            <>Scheduled</>
                                        ) : (
                                            'By: '
                                        )}
                                    </p>
                                    <p className='user-text'>
                                        {store.email === null ? 'Unavailable' :
                                            store.email === 'scheduled' ? '' :
                                                store.email.includes('@') ? store.email.split('@')[0] :
                                                    store.email
                                        }
                                    </p>
                                </div>
                                <div className='user-info-div lapseRow'>
                                    <p className='user-title'>
                                        Duration:
                                    </p>
                                    <p className='user-text'>
                                        {store.lapse === null ? 'Unavailable' :
                                            store.lapse < 1 ? 'Instant' :
                                                `${store.lapse} min`
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="management-div">
                    <IconButton
                        aria-label="more"
                        onClick={manageStore}
                        aria-haspopup="true"
                        aria-controls="long-menu"
                    >
                        <EditIconNew></EditIconNew>
                    </IconButton>
                </div>
            </div>
        </>
    )
}