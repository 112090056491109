import React, { useEffect, useState } from "react";
import TabContainerHOC from "./hocs/TabContainerHOC";
import DemoImage from '../../assets/customization/demo.png';

interface Category {
    src: string;
    text: string;
    url: string;
}

interface Titles {
    title: string;
    subtitle: string;
}

//mock
const mock4Categories = {
    title: '',
    subtitle: '',
    category1: {
        src: '',
        text: '',
        url: '',
    },
    category2: {
        src: '',
        text: '',
        url: '',
    },
    category3: {
        src: '',
        text: '',
        url: '',
    },
    category4: {
        src: '',
        text: '',
        url: '',
    }
}
//--

export default function CategoriesPage() {
    const [titles, setTitles] = useState<Titles>({ title: mock4Categories.title, subtitle: mock4Categories.subtitle });
    const [category1, setCategory1] = useState<Category>(mock4Categories.category1);
    const [category2, setCategory2] = useState<Category>(mock4Categories.category2);
    const [category3, setCategory3] = useState<Category>(mock4Categories.category3);
    const [category4, setCategory4] = useState<Category>(mock4Categories.category4);
    const [calling, setCalling] = useState<boolean>(true);

    useEffect(() => {
        //TODO: call api
        //mock
        setTimeout(() => {
            setTitles({
                title: mock4Categories.title,
                subtitle: mock4Categories.subtitle
            });
            setCategory1({
                src: mock4Categories.category1.src,
                text: mock4Categories.category1.text,
                url: mock4Categories.category1.url,
            })
            setCategory2({
                src: mock4Categories.category2.src,
                text: mock4Categories.category2.text,
                url: mock4Categories.category2.url,
            })
            setCategory3({
                src: mock4Categories.category3.src,
                text: mock4Categories.category3.text,
                url: mock4Categories.category3.url,
            })
            setCategory4({
                src: mock4Categories.category4.src,
                text: mock4Categories.category4.text,
                url: mock4Categories.category4.url,
            })
            setCalling(false);
        }, 1500);
        //--
    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setTitles({ ...titles, [e.target.id]: e.target.value })
    }

    const handleCategory = (e: React.ChangeEvent<HTMLInputElement>, id: string, isImg: boolean = false): void => {
        let img: string | undefined = undefined;
        if (isImg) {
            img = 'https://bigfurnitureshow.com/assets/missingImage.ea9d6c9f.jpg'
            //TODO: send to shopify
        }
        switch (id) {
            case "1":
                if (img) setCategory1({ ...category1, src: img });
                else setCategory1({ ...category1, [e.target.id]: e.target.value });
                break;
            case "2":
                if (img) setCategory2({ ...category2, src: img });
                else setCategory2({ ...category2, [e.target.id]: e.target.value });
                break;
            case "3":
                if (img) setCategory3({ ...category3, src: img });
                else setCategory3({ ...category3, [e.target.id]: e.target.value });
                break;
            case "4":
                if (img) setCategory4({ ...category4, src: img });
                else setCategory4({ ...category4, [e.target.id]: e.target.value });
                break;
        }
    }

    const send = (): void => {
        setCalling(true);
        //mock
        setTimeout(() => {
            const body = {
                title: titles.title,
                subtitle: titles.subtitle,
                category1,
                category2,
                category3,
                category4
            }
            console.log('SEND TO API: ', body)
            setCalling(false);
            alert('SUCCESSFUL SAVE (TODO: improve)');
        }, 700)
        //--
    }

    return (
        <TabContainerHOC commingSoon title="4 Categories Section" description={<Description />} containerClass="categories" loading={calling}>

            <TitleSubtitle titles={titles} handleChange={handleChange} />

            <div className="row mt-5">
                <div className="col">
                    <h2>Add Images and links</h2>
                </div>
            </div>

            <div className="row mt-3">
                <Category category={category1} id="1" containerClass='col-left' handleCategory={handleCategory} />
                <Category category={category2} id="2" containerClass='col-right pl-35 bl-1' handleCategory={handleCategory} />
            </div>

            <hr />

            <div className="row mt-5">
                <Category category={category3} id="3" containerClass='col-left' handleCategory={handleCategory} />
                <Category category={category4} id="4" containerClass='col-right pl-35 bl-1' handleCategory={handleCategory} />
            </div>

            <div className="navigationFooter mt-5">
                <a className="backBtn">BACK</a>
                <span className="saveBtn" onClick={send}>SAVE</span>
            </div>

        </TabContainerHOC>
    )
}

const Description = (): JSX.Element => (
    <ul>
        <li>Add block Title and Subtitle.</li>
        <li>Add the 4 images, button text and link.</li>
    </ul>
)

interface TitleSubtitleProps {
    titles: Titles;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const TitleSubtitle: React.FC<TitleSubtitleProps> = ({ titles, handleChange }) => {
    return (
        <>
            <div className="row mt-5">
                <div className="col">
                    <h2>Add Title and Subtitle</h2>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-sm col-left">
                    <p><b>Add Title</b></p>
                    <p>Recommended 40 characters approximately</p>
                    <input id="title" onChange={handleChange} value={titles.title} type="text" className="input-round form-control" placeholder="Type text." />
                </div>
                <div className="col-sm col-right pl-7 bigInput">
                    <p><b>Add Subtitle</b></p>
                    <p>Recommended 50 - 100 characters approximately</p>
                    <input id="subtitle" onChange={handleChange} value={titles.subtitle} type="text" className="input-round form-control mt-2" placeholder="Type text." />
                </div>
            </div>

            <hr />
        </>
    )
}

interface CategoryProps {
    id: string;
    containerClass: string;
    handleCategory: (e: React.ChangeEvent<HTMLInputElement>, id: string, isImg?: boolean) => void;
    category: Category;
}

const Category: React.FC<CategoryProps> = ({ id, containerClass, handleCategory, category }) => {
    return (
        <div className={`col-sm ${containerClass}`}>
            <div className="headerCategories">
                <div className="greeNumber">
                    <p>{id}</p>
                </div>
                <div className="headerDesciption">
                    <p><b>Image for Desktop - Mobile</b></p>
                    <p>1300x 1000 pix. or more.<br /> Visible area: Aspect Ratio 4:3
                    </p>
                </div>
            </div>
            <div className="container up mt-2">
                <div className="avatar-upload">
                    <div className="avatar-edit">
                        <input onChange={(e) => handleCategory(e, id, true)} type='file' id="imageUpload" accept=".png, .jpg, .jpeg" />
                        <label htmlFor="imageUpload"></label>
                    </div>
                    <div className="avatar-preview">
                        {category.src !== '' ? <div id="imagePreview" style={{ backgroundImage: `url(${category.src})`, backgroundSize: 'cover' }} />
                            : <div id="imagePreview" style={{ backgroundImage: `url(${DemoImage})` }} />}
                    </div>
                </div>
                <h4>Upload Photo</h4>
            </div>
            <p className="mt-3"><b>Text of the button</b></p>
            <input value={category.text} onChange={(e) => handleCategory(e, id)} id="text" type="text" className="input-round form-control mt-2 text-center" placeholder="EXAMPLE: LIVING ROOM" />
            <input value={category.url} onChange={(e) => handleCategory(e, id)} id="url" type="text" className="input-round form-control mt-2 link" placeholder="https://" />
        </div>
    )
}