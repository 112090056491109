import React, { useEffect, useState } from "react";
import TabContainerHOC from "./hocs/TabContainerHOC";
import axios from "axios";
import DemoImage from '../../assets/customization/demo.png';
import Notification from "./hocs/NotificationHOC";
import { Store } from "rc-field-form/lib/interface";
import { getStoreById } from "../../lib/admin";

interface SliderItem {
    src: string;
    h2: string;
    p: string;
    a: string;
    url: string;
    showButton: boolean;
    redirectImage: boolean;
    logo: string;
}

interface TopSliderPageProps {
    model: {
        storeId: string;
    };
}

export default function TopSliderPage({ model }: TopSliderPageProps) {
    const [store, setStore] = useState<undefined | Store>(undefined);
    const [sliderOn, setSliderOn] = useState<boolean>();
    const [interval, setInterval] = useState<number>();
    const [items, setItems] = useState<SliderItem[]>([]);
    const [originalData, setOriginalData] = useState<{ sliderOn: boolean; interval: number; items: SliderItem[] } | null>(null);
    const [calling, setCalling] = useState<boolean>(true);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [loadError, setLoadError] = useState<string | null>(null);
    const [notification, setNotification] = useState<{ message: string | null; type: "success" | "error" }>({
        message: null,
        type: "success",
    });

    const loadStore = async () => {
        try {
            const storeData = await getStoreById(parseInt(model.storeId));
            setStore(storeData);
        } catch (error) {
            console.error("Failed to load store:", error);
            setStore(undefined);
        }
    };

    useEffect(() => {
        loadStore();
        axios.get(`${process.env.REACT_APP_API_BASE_URL}ws/Customization/GetMetafield/HomepageCarousel`, {
            headers: {
                'accept': '*/*',
                'WS-API-KEY': process.env.REACT_APP_WS_API_KEY,
                'Content-Type': 'application/json',
                'WS-STORE-ID': model.storeId,
            },
        })
            .then((data) => {
                const { sliderOn, interval, items } = data.data;
                const initialData = {
                    sliderOn: sliderOn !== undefined ? sliderOn : false,
                    interval: interval || 0,
                    items: items?.length ? items : [],
                };
                setSliderOn(initialData.sliderOn);
                setInterval(initialData.interval);
                setItems(initialData.items);
                setOriginalData(initialData);
            })
            .catch((err) => {
                console.error("Error fetching data:", err);
                setLoadError("Failed to load data. Please try again or contact support.");
            })
            .finally(() => {
                setCalling(false);
            });
    }, [model.storeId]);

    const showNotification = (message: string, type: "success" | "error") => {
        setNotification({ message, type });
    };

    const checkForChanges = (newData: { sliderOn: boolean; interval: number; items: SliderItem[] }) => {
        if (!originalData) return false;
        return (
            newData.sliderOn !== originalData.sliderOn ||
            newData.interval !== originalData.interval ||
            JSON.stringify(newData.items) !== JSON.stringify(originalData.items)
        );
    };

    const handleImage = async (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
        setCalling(true);
        const file = e.target.files?.[0];

        const field = e.target.id.startsWith("logo") ? "logo" : "src";
        const MAX_FILE_SIZE = 1024 * 1024;

        if (!file) {
            setCalling(false);
            return;
        }
        if (file.size > MAX_FILE_SIZE) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            showNotification(`File size exceeds the maximum limit of ${MAX_FILE_SIZE / 1024 / 1024} MB.`, 'error');
            setCalling(false);
            return;
        }

        const image = new Image();
        const fileReader = new FileReader();

        fileReader.onload = (event) => {
            if (event.target?.result) {
                image.src = event.target.result as string;

                image.onload = () => {
                    const formData = new FormData();
                    formData.append('image', file);

                    axios
                        .post(`${process.env.REACT_APP_API_BASE_URL}ws/Assets/UploadImage`, formData, {
                            headers: {
                                accept: '*/*',
                                'WS-API-KEY': process.env.REACT_APP_WS_API_KEY || '',
                                'Content-Type': 'multipart/form-data',
                                'WS-STORE-ID': model.storeId,
                            },
                            responseType: 'text',
                        })
                        .then((response) => {
                            const imageUrl = response.data;
                            if (!imageUrl) throw new Error('No image URL returned from API');
                            const updatedItems = [...items];
                            updatedItems[i] = { ...updatedItems[i], [field]: imageUrl };
                            setItems(updatedItems);
                            setHasChanges(
                                checkForChanges({ sliderOn: sliderOn!, interval: interval!, items: updatedItems })
                            );
                        })
                        .catch((err) => {
                            console.error('Error uploading image:', err);
                            showNotification('Image upload failed. Please try again.', 'error');
                        })
                        .finally(() => {
                            setCalling(false);
                        });
                };
            }
        };

        fileReader.readAsDataURL(file);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, i: number) => {
        const item = { ...items[i], [e.target.id]: e.target.value };
        const newItems = [...items];
        newItems[i] = item;
        setItems(newItems);
        setHasChanges(checkForChanges({ sliderOn: sliderOn!, interval: interval!, items: newItems }));
    };

    const handleSliderOnChange = (state: boolean) => {
        setSliderOn(!sliderOn);
        setHasChanges(checkForChanges({ sliderOn: !sliderOn!, interval: interval!, items }));
    };

    const handleCancel = () => {
        if (originalData) {
            setSliderOn(originalData.sliderOn);
            setInterval(originalData.interval);
            setItems(originalData.items);
            setHasChanges(false);
        }
    };

    const addStep = () => {
        let newItem: SliderItem = {
            src: '',
            h2: '',
            p: '',
            a: '',
            url: '#',
            showButton: true,
            redirectImage: false,
            logo: '',
        };
        const updatedItems = [...items, newItem];
        setItems(updatedItems);
        setHasChanges(checkForChanges({ sliderOn: sliderOn!, interval: interval!, items: updatedItems }));
    };

    const removeStep = (i: number) => {
        const updatedItems = [...items];
        updatedItems.splice(i, 1);
        setItems(updatedItems);
        setHasChanges(checkForChanges({ sliderOn: sliderOn!, interval: interval!, items: updatedItems }));
    };

    const send = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (!hasChanges) {
            showNotification("No changes detected.", "error");
            return;
        }
        setCalling(true);

        const body = {
            sliderOn,
            interval,
            items,
        };
        axios.post(`${process.env.REACT_APP_API_BASE_URL}ws/Customization/UpsertMetafield/HomepageCarousel`, body, {
            headers: {
                'accept': '*/*',
                'WS-API-KEY': process.env.REACT_APP_WS_API_KEY || '',
                'Content-Type': 'application/json',
                'WS-STORE-ID': model.storeId,
            },
        })
            .then(() => {
                showNotification("Update successful.", "success");
                setOriginalData({ sliderOn: sliderOn!, interval: interval!, items });
                setHasChanges(false);
            })
            .catch((err) => {
                console.error('Error saving settings:', err);
                showNotification("Failed to save settings. Please try again.", "error");
            })
            .finally(() => {
                setCalling(false);
            });
    };

    if (loadError) {
        return (
            <TabContainerHOC title="Carousel" description={<Description />} containerClass="top-slider" loading={calling}>
                <div className="error-container">
                    <div className="alert alert-danger">{loadError}</div>
                </div>
            </TabContainerHOC>
        );
    };

    return (
        <TabContainerHOC title="Carousel" description={<Description />} containerClass="top-slider" loading={calling}>
            <div className="notification-container">
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification({ message: null, type: "success" })}
                />
            </div>
            {items.map((it, i) => (
                <Step
                    key={i}
                    item={it}
                    i={i}
                    sliderOn={sliderOn}
                    storePlan={store?.storePlan}
                    handleSliderOnChange={handleSliderOnChange}
                    handleImage={handleImage}
                    handleChange={handleChange}
                    removeStep={removeStep}
                />
            ))}
            {(store?.storePlan === 5 || store?.storePlan === 6) && items.length >= 1 ? (
                <></>
            ) : (
                <div className="col addStep">
                    <a onClick={addStep}>
                        <h2>+ Add Step {items.length + 1}</h2>
                    </a>
                </div>
            )}

            <div className="navigationFooter mt-5">
                <a className="backBtn" onClick={handleCancel}>CANCEL</a>
                <span className="saveBtn" onClick={send}>
                    SAVE
                </span>
            </div>
        </TabContainerHOC>
    );
}

const Description = () =>
    <ul>
        <li>Add images, texts and buttons on each step of the slide.</li>
        <li>Disable the "<b>Slider</b>" option if you just want a static image.</li>
    </ul>

interface StepProps {
    item: SliderItem;
    i: number;
    sliderOn?: boolean;
    storePlan?: number;
    handleSliderOnChange: (value: boolean) => void;
    handleImage: (e: React.ChangeEvent<HTMLInputElement>, i: number) => void;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>, i: number) => void;
    removeStep: (i: number) => void;
}

const Step: React.FC<StepProps> = ({
    item,
    i,
    sliderOn,
    handleSliderOnChange,
    handleImage,
    handleChange,
    removeStep,
    storePlan
}) => {
    const isRestricted = storePlan === 5 || storePlan === 6;

    return (
        <>
            <div className="row mt-5">
                <div className="col-sm col-left">
                    <h2>Step {i + 1}</h2>
                </div>
                {i === 0 && (
                    <div className={`col-sm col-right text-right ${isRestricted ? 'disabled' : ''}`}>
                        <div className="custom-control custom-switch">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customSwitch1"
                                onChange={() => handleSliderOnChange(!sliderOn)}
                                checked={sliderOn}
                                disabled={isRestricted}
                            />
                            <label className="custom-control-label" htmlFor="customSwitch1">Slider ON</label>
                        </div>
                    </div>
                )}
                {i !== 0 && (
                    <div className="col-sm col-right text-right">
                        <span id="remove-btn" onClick={() => removeStep(i)}>Remove (X)</span>
                    </div>
                )}
            </div>

            <div className="row mt-2">
                <div className="col-sm col-left">
                    <p><b>Primary text over the image</b></p>
                    <p>Recommended 40 characters approximately</p>
                    <input
                        id="h2"
                        type="text"
                        className="input-round form-control"
                        placeholder="Type text."
                        onChange={(e) => handleChange(e, i)}
                        value={item.h2}
                    />
                </div>
                <div className={`col-sm col-right pl-7 ${isRestricted ? 'disabled' : ''}`}>
                    <p><b>Secondary text over the image</b></p>
                    <p>Recommended 40 characters approximately</p>
                    <input
                        id="p"
                        type="text"
                        className="input-round form-control"
                        placeholder="Type text."
                        onChange={(e) => handleChange(e, i)}
                        value={item.p}
                        disabled={isRestricted}
                    />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-sm-5">
                    <p><b>Image for Desktop</b></p>
                    <p>Visible area 1920x620 pix.</p>
                    <div className="container up mt-4">
                        <div className="avatar-upload">
                            <div className="avatar-edit">
                                <input
                                    type="file"
                                    id={`src-${i}`}
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) => handleImage(e, i)}
                                    disabled={isRestricted}
                                />
                                <label htmlFor={`src-${i}`}></label>
                            </div>
                            <div className="avatar-preview">
                                {item.src !== '' ? (
                                    <div
                                        id="imagePreview"
                                        style={{ backgroundImage: `url(${item.src})`, backgroundSize: 'cover' }}
                                    />
                                ) : (
                                    <div id="imagePreview" style={{ backgroundImage: `url(${DemoImage})` }} />
                                )}
                            </div>
                        </div>
                        <h4>Upload Photo</h4>
                    </div>
                </div>
                <div className={`col-sm-2 ${isRestricted ? 'disabled' : ''}`}>
                    <p><b>Logo</b></p>
                    <p>Visible on the desktop image.</p>
                    <div className="container up smallup">
                        <div className="avatar-upload">
                            <div className="avatar-edit">
                                <input
                                    type="file"
                                    id={`logo-${i}`}
                                    accept=".png, .jpg, .jpeg"
                                    onChange={(e) => handleImage(e, i)}
                                    disabled={isRestricted}
                                />
                                <label htmlFor={`logo-${i}`}></label>
                            </div>
                            <div className="avatar-preview">
                                {item.logo !== '' ? (
                                    <div
                                        id="imagePreview"
                                        style={{ backgroundImage: `url(${item.logo})`, backgroundSize: 'cover' }}
                                    />
                                ) : (
                                    <div id="imagePreview" style={{ backgroundImage: `url(${DemoImage})` }} />
                                )}
                            </div>
                        </div>
                        <h4>Upload Photo</h4>
                    </div>
                </div>
                <div className={`col-sm-5 ${isRestricted ? 'disabled' : ''}`}>
                    <p><b>Button</b></p>
                    <p>Text of the button</p>
                    <input
                        id="a"
                        onChange={(e) => handleChange(e, i)}
                        value={item.a}
                        type="text"
                        className="input-round form-control mt-4 text-center"
                        placeholder="EXPLORE COLLECTION"
                        disabled={isRestricted}
                    />
                    <input
                        id="url"
                        onChange={(e) => handleChange(e, i)}
                        value={item.url}
                        type="text"
                        className="input-round form-control mt-2 link"
                        placeholder="https://"
                        disabled={isRestricted}
                    />
                </div>
            </div>
            <hr></hr>
        </>
    );
};