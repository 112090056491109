import React from "react";
import { Spin } from "antd";

interface WithLoadingProps {
  isFetching: boolean;
  [key: string]: any; // Allow any additional props
}

type ComponentOrString<P = any> = React.ComponentType<P> | keyof JSX.IntrinsicElements;

function withLoading<P extends object>(Component: ComponentOrString<P>): React.FC<P & WithLoadingProps> {
  return ({ isFetching, ...props }: WithLoadingProps) => (
    <div>
      {isFetching && (
        <Spin tip="Loading..."></Spin>
      )}
      {!isFetching && (
        typeof Component === 'string'
          ? React.createElement(Component, props)
          : <Component {...(props as P)} />
      )}
    </div>
  );
}

export default withLoading;
