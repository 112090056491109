import React, { ReactNode } from "react";

interface TabContainerHOCProps {
  title?: string;
  description?: ReactNode;
  containerClass?: string;
  loading?: boolean;
  children: ReactNode;
  commingSoon?: boolean;
}

const TabContainerHOC: React.FC<TabContainerHOCProps> = ({
  title,
  description,
  containerClass,
  loading,
  children,
  commingSoon = false,
}) => {
  return (
    <div className={`container ${containerClass}`}>
      <div className="row topBar"></div>
      <div className="row titleHeader">
        <h1>{title}</h1>
      </div>
      <div className={`wrapperContent ${commingSoon ? "coming-soon" : ""}`}>
        <div className="row">
          <div className="col pl-0 mt-3">{description}</div>
        </div>
        {loading ? (
          <div className="loader-container">
            <div className="loader">
              {Array.from({ length: 50 }).map((_, idx) => (
                <div key={idx} className="dot"></div>
              ))}
            </div>
          </div>
        ) : (
          <>
            {commingSoon && (
              <div className="disabled-overlay">
                <div className="coming-soon-message">
                  <h1>Coming Soon</h1>
                  <p>
                    A new update is just around the corner. Coming soon to enhance your experience.
                  </p>
                </div>
              </div>
            )}
            {children}
          </>
        )}
      </div>
    </div>
  );
};

export default TabContainerHOC;