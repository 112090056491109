import React, { useEffect, useState } from "react";
import TabContainerHOC from "./hocs/TabContainerHOC";
import FreeDeliveryIcon from "../../assets/customization/FreeDeliveryIcon";
import PricePromiseIcon from "../../assets/customization/PricePromiseIcon";
import BrandNameIcon from "../../assets/customization/BrandNameIcon";
import FurnitureDisposalIcon from "../../assets/customization/FurnitureDisposalIcon";
import CustomerSatisfactionIcon from "../../assets/customization/CustomerSatifactionIcon";
import YearsIcon from "../../assets/customization/YearsIcon";

interface Icon {
  available: boolean;
  title: string;
  subtitle: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

// mock
const mockIcons: Icon[] = [
  { available: true, title: '', subtitle: '', icon: FreeDeliveryIcon },
  { available: false, title: '', subtitle: '', icon: PricePromiseIcon },
  { available: false, title: '', subtitle: '', icon: BrandNameIcon },
  { available: true, title: '', subtitle: '', icon: FurnitureDisposalIcon },
  { available: true, title: '', subtitle: '', icon: CustomerSatisfactionIcon },
  { available: false, title: '', subtitle: '', icon: YearsIcon }
];

export default function ValuesPage() {
  const [icons, setIcons] = useState<Icon[]>(mockIcons);
  const [calling, setCalling] = useState<boolean>(true);

  useEffect(() => {
    // TODO: call api
    // mock
    setTimeout(() => {
      setIcons(mockIcons);
      setCalling(false);
    }, 1500);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, i: number): void => {
    const icon = { ...icons[i], [e.target.id]: e.target.value };
    const newIcons = [...icons];
    newIcons[i] = icon;
    setIcons(newIcons);
  };

  const handleAvailable = (i: number): void => {
    const icon = { ...icons[i], available: !icons[i].available };
    const newIcons = [...icons];
    newIcons[i] = icon;
    setIcons(newIcons);
  };

  const checkCounter = (): number => {
    return icons.filter(i => i.available).length;
  };

  const send = (): void => {
    if (checkCounter() > 2) {
      setCalling(true);
      // mock
      setTimeout(() => {
        const body = {
          ...icons
        };
        console.log('SEND TO API: ', body);
        setCalling(false);
        alert('SUCCESSFUL SAVE (TODO: improve)');
      }, 700);
    }
  };

  return (
    <TabContainerHOC commingSoon title="Values - Why To Choose Us" description={<Description />} containerClass="values" loading={calling}>
      <div className="row mt-5">
        <div className="col-sm col-left">
          <h2>Choose Icon and edit text</h2>
        </div>
        <div className="col-sm col-right text-right pr-4">
          <h2>Select ({checkCounter()})</h2>
        </div>
      </div>

      <div className="iconWrapper mt-4">
        {icons.map((ic, i) => (
          <React.Fragment key={i}>
            {i !== 0 && <hr />}
            <Value icon={ic} id={i} handleChange={handleChange} handleAvailable={handleAvailable} />
          </React.Fragment>
        ))}
      </div>

      <div className="navigationFooter mt-5">
        {checkCounter() < 3 && <li>You must select at least 3 icons</li>}
        <a className="backBtn">BACK</a>
        <span className={`saveBtn ${checkCounter() < 3 ? 'disabled' : ''}`} onClick={send}>SAVE</span>
      </div>
    </TabContainerHOC>
  );
}

const Description = () => (
  <ul>
    <li>Select a <b>maximum of 6</b> icons and a <b>minimum of 3.</b></li>
  </ul>
);

interface ValueProps {
  icon: Icon;
  id: number;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
  handleAvailable: (id: number) => void;
}

const Value: React.FC<ValueProps> = ({ icon, id, handleChange, handleAvailable }) => {
  const IconComponent = icon.icon;
  
  return (
    <div className="row">
      <div className="col-sm-2 iconDraw">
        <IconComponent />
      </div>
      <div className="col-sm-4 p5">
        <p><b>Title</b><span className="esl">Recommended 30 characters </span></p>
        <input id="title" value={icon.title} onChange={(e) => handleChange(e, id)} type="text" className="input-round form-control mt-2" placeholder="Type text." />
      </div>
      <div className="col-sm-4 p5">
        <p><b>Subtitle</b><span className="esl">Recommended 40 characters </span></p>
        <input id="subtitle" value={icon.subtitle} onChange={(e) => handleChange(e, id)} type="text" className="input-round form-control mt-2" placeholder="Type text." />
      </div>
      <div className="col-sm-2 checkIcon">
        <input onChange={() => handleAvailable(id)} className="form-check-input" type="checkbox" checked={icon.available} />
      </div>
    </div>
  );
};