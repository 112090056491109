export default function CustomerSatisfactionIcon() {
    return (
        <svg width="91" height="91" viewBox="0 0 91 91" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.7" y="-0.000183105" width="91" height="91" rx="45.5" fill="white" />
            <g clip-path="url(#clip0_240_6)">
                <path d="M54.5396 46.5993C54.8392 46.3745 55.2887 46.2247 55.6632 46.2247C57.9104 46.2247 60.2325 46.2247 62.4797 46.2247H64.8018C65.5508 46.2247 65.9254 45.8502 65.9254 45.1011V27.1236C65.9254 26.3745 65.5508 26 64.8018 26C57.0864 26 49.2212 26 41.431 26C40.607 26 40.2325 26.3745 40.2325 27.2734V36.7116V40.2322H39.4834C39.4085 40.2322 39.4085 40.2322 39.3336 40.2322C39.2587 40.2322 39.2587 40.2322 39.1838 40.2322C38.8842 40.2322 38.5845 40.2322 38.3598 40.3071C37.6857 40.3071 37.0864 40.382 36.4872 40.4569C33.1164 41.1311 30.195 43.0037 28.2475 45.9251C26.2999 48.8464 25.6257 52.2921 26.2999 55.7378C27.049 59.2584 29.2212 62.3296 32.2924 64.2023C35.3636 66.0749 39.1089 66.5243 42.5546 65.4757C49.1463 63.5281 53.0415 56.7116 51.3935 50.0449C51.3186 49.6704 51.2437 49.221 51.843 48.7715C52.2924 48.4719 52.7418 48.0974 53.1164 47.7978C53.6407 47.3483 54.0902 46.9738 54.5396 46.5993ZM44.7269 62.2547C42.9291 63.4532 40.9066 63.9775 38.8093 63.9775C36.1126 63.9775 33.4909 63.0037 31.4684 61.0562C27.798 57.6854 26.974 52.2172 29.2961 47.8727C31.6182 43.603 36.2624 41.8802 39.7081 42.3296L40.2325 42.4794V45.1011C40.2325 45.8502 40.607 46.2247 41.3561 46.2247C42.4797 46.2247 43.5284 46.2247 44.652 46.2247H46.5995V49.6704C46.5995 49.97 46.5995 50.3446 46.5995 50.6442C46.5995 51.0936 46.7493 51.3933 47.1239 51.618C47.4984 51.8427 47.8729 51.7678 48.2475 51.4682L49.2961 50.7191L49.5209 51.5431C50.195 54.3146 49.1463 59.4082 44.7269 62.2547ZM50.7194 46.9738L48.5471 48.6217V46.6742C48.5471 46.1498 48.5471 45.7004 48.5471 45.2509C48.5471 44.5768 48.1726 44.2022 47.4984 44.1273C46.4497 44.1273 45.3261 44.1273 44.2774 44.1273H42.3299V28.0974H63.9778V44.2022H60.8317C58.8842 44.2022 57.0115 44.2022 55.0639 44.2022C54.6145 44.2022 54.24 44.3521 53.8654 44.6517C52.8167 45.4008 51.7681 46.1498 50.7194 46.9738Z" fill="#233638" />
                <path d="M58.8838 33.3408C58.8838 33.1161 58.8089 32.8165 58.5842 32.6667C58.1348 32.2172 57.5355 32.2921 57.0112 32.7416C56.1123 33.5655 55.2883 34.4644 54.3894 35.2884L53.0411 36.6367C52.9662 36.7116 52.8913 36.7865 52.8164 36.8614L52.2172 37.3858L51.4681 36.5618C51.2434 36.2622 50.9437 36.0375 50.719 35.8127C50.4943 35.588 50.1947 35.4382 49.8951 35.4382C49.6703 35.4382 49.3707 35.5131 49.2209 35.7378C48.7715 36.1873 48.8464 36.7865 49.2958 37.3109C50.0449 38.0599 50.719 38.809 51.4681 39.4832C51.9924 40.0075 52.5917 40.0075 53.116 39.4832C54.9138 37.6854 56.7115 35.9626 58.4344 34.1648C58.734 33.9401 58.8838 33.5655 58.8838 33.3408Z" fill="#233638" />
                <path d="M38.7349 60.4569C40.9072 60.4569 42.6301 59.7079 44.0533 58.2846C44.5027 57.8352 44.5027 57.2359 44.1282 56.7865C43.9035 56.5618 43.6788 56.4869 43.3791 56.4869C43.0795 56.4869 42.8548 56.5618 42.6301 56.7865C41.5814 57.9101 40.1582 58.4344 38.66 58.4344C37.1619 58.3595 35.7387 57.6854 34.7649 56.5618C34.5402 56.3371 34.3155 56.1873 34.0158 56.1873H33.9409C33.7162 56.1873 33.4915 56.2622 33.2668 56.4869C32.8173 56.8614 32.8173 57.5356 33.2668 58.0599C34.7649 59.558 36.6376 60.4569 38.7349 60.4569Z" fill="#233638" />
                <path d="M34.4645 50.9438C34.8391 50.9438 35.2136 50.794 35.5132 50.5693C35.7379 50.3446 35.8877 49.97 35.8877 49.5955C35.8877 48.7715 35.2885 48.1723 34.5394 48.1723C34.1649 48.1723 33.7904 48.3221 33.4907 48.5468C33.266 48.7715 33.1162 49.1461 33.1162 49.5206C33.1162 50.3446 33.7155 50.9438 34.4645 50.9438Z" fill="#233638" />
                <path d="M43.2289 48.1723C42.4049 48.1723 41.8057 48.7715 41.8057 49.5955C41.8057 50.4195 42.4049 51.0187 43.154 51.0187C43.9779 51.0187 44.5772 50.4195 44.5772 49.6704C44.5772 49.2959 44.4274 48.9213 44.2027 48.6217C43.9779 48.3221 43.6034 48.1723 43.2289 48.1723Z" fill="#233638" />
            </g>
            <defs>
                <clipPath id="clip0_240_6">
                    <rect width="40" height="40" fill="white" transform="translate(26 26)" />
                </clipPath>
            </defs>
        </svg>
    )
}
