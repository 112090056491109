import React, { useEffect, useState } from "react";
import TabContainerHOC from "./hocs/TabContainerHOC";
import DemoImage from '../../assets/customization/demo.png';

//mock
interface Banner {
    primaryText: string;
    secondaryText: string;
    url: string;
    desktopSrc: string;
    mobileSrc: string;
}

const mockBanner: Banner = {
    primaryText: '',
    secondaryText: '',
    url: '',
    desktopSrc: '',
    mobileSrc: ''
}
//--

const BannerLandscapePage: React.FC = () => {
    const [banner, setBanner] = useState<Banner>(mockBanner);
    const [calling, setCalling] = useState<boolean>(true);

    useEffect(() => {
        //TODO: call api
        //mock
        setTimeout(() => {
            setBanner(mockBanner);
            setCalling(false);
        }, 1500);
        //--
    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, isImg: boolean = false): void => {
        console.log(e.target.name)
        let img = undefined;
        if (isImg) {
            img = 'https://bigfurnitureshow.com/assets/missingImage.ea9d6c9f.jpg'
            //TODO: send to shopify
            setBanner({ ...banner, [e.target.id]: img })
        }
        else setBanner({ ...banner, [e.target.id]: e.target.value })
    }

    const send = (): void => {
        setCalling(true);
        //mock
        setTimeout(() => {
            const body = {
                ...banner
            }
            console.log('SEND TO API: ', body)
            setCalling(false);
            alert('SUCCESSFUL SAVE (TODO: improve)');
        }, 700)
        //--
    }

    return (
        <TabContainerHOC commingSoon title="Banner 1 landscape" description={<Description />} containerClass="banner-landscape" loading={calling}>

            <Option1 banner={banner} handleChange={handleChange} />

            <hr />

            <Option2 banner={banner} handleChange={handleChange} />

            <div className="navigationFooter mt-5">
                <a className="backBtn">BACK</a>
                <span className="saveBtn" onClick={send}>SAVE</span>
            </div>
        </TabContainerHOC>
    )
}

const Description = (): JSX.Element => (
    <ul>
        <li>Option 1: Add texts and link</li>
        <li>Option 2: Add image and link</li>
    </ul>
)

interface OptionProps {
    banner: Banner;
    handleChange: (e: React.ChangeEvent<HTMLInputElement>, isImg?: boolean) => void;
}

const Option1: React.FC<OptionProps> = ({ banner, handleChange }) => {
    return (
        <>
            <div className="row mt-5">
                <div className="col">
                    <h2>Option 1</h2>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-sm col-left">
                    <p><b>Add primary text</b></p>
                    <p>Recommended 30 characters approximately</p>
                    <input id="primaryText" value={banner.primaryText} onChange={handleChange} type="text" className="input-round form-control" placeholder="Type text." />
                    <p className="mt-3"><b>Shop Now Button</b><span className="esl"> Insert link</span></p>
                    <input id="url" value={banner.url} onChange={handleChange} type="text" className="input-round form-control mt-2 link" placeholder="https://" />
                </div>
                <div className="col-sm col-right pl-7 ">
                    <p><b>Add secondary text</b></p>
                    <p>Recommended 50 characters approximately</p>
                    <input id="secondaryText" value={banner.secondaryText} onChange={handleChange} type="text" className="input-round form-control" placeholder="Type text." />
                </div>
            </div>
        </>
    )
}

const Option2: React.FC<OptionProps> = ({ banner, handleChange }) => {
    return (
        <>
            <div className="row mt-5">
                <div className="col">
                    <h2>Option 2</h2>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-sm-8 col-left">
                    <p><b>Image desktop</b></p>
                    <p>Image size  1400 x 215 pix</p>
                    <div className="container up mt-2">
                        <div className="avatar-upload">
                            <div className="avatar-edit">
                                <input onChange={(e) => handleChange(e, true)} type='file' id="desktopSrc" accept=".png, .jpg, .jpeg" />
                                <label htmlFor="desktopSrc"></label>
                            </div>
                            <div className="avatar-preview">
                                {banner.desktopSrc !== '' ? <div id="imagePreview" style={{ backgroundImage: `url(${banner.desktopSrc})`, backgroundSize: 'cover' }} />
                                    : <div id="imagePreview" style={{ backgroundImage: `url(${DemoImage})` }} />}
                            </div>
                        </div>
                        <h4>Upload Photo</h4>
                    </div>
                    {/* <p className="mt-4">Intert link</p>
                    <input type="text" className="input-round form-control mt-2 link" placeholder="https://" /> */}
                </div>

                <div className="col-sm-4 col-right">
                    <p><b>Image mobile</b></p>
                    <p>Image size  420 x 260 pix</p>
                    <div className="container up mt-2">
                        <div className="avatar-upload">
                            <div className="avatar-edit">
                                <input onChange={(e) => handleChange(e, true)} type='file' id="mobileSrc" accept=".png, .jpg, .jpeg" />
                                <label htmlFor="mobileSrc"></label>
                            </div>
                            <div className="avatar-preview">
                                {banner.mobileSrc !== '' ? <div id="imagePreview" style={{ backgroundImage: `url(${banner.mobileSrc})`, backgroundSize: 'cover' }} />
                                    : <div id="imagePreview" style={{ backgroundImage: `url(${DemoImage})`}} />}
                            </div>
                        </div>
                        <h4>Upload Photo</h4>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BannerLandscapePage;