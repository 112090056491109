import React, { useEffect, useState, ChangeEvent } from "react";
import TabContainerHOC from "./hocs/TabContainerHOC";
import DemoImage from '../../assets/customization/demo.png';

const nav = `.`;

// mock
const mockBanner = {
  primaryText: "",
  secondaryText: "",
  url: "",
  src: "",
};

interface Banner {
  primaryText: string;
  secondaryText: string;
  url: string;
  src: string;
}

const BannerFooterPage: React.FC = () => {
  const [banner, setBanner] = useState<Banner>(mockBanner);
  const [calling, setCalling] = useState<boolean>(true);

  useEffect(() => {
    // TODO: Call API to fetch banner data
    setTimeout(() => {
      setBanner(mockBanner);
      setCalling(false);
    }, 1500);
  }, []);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { id, value, files } = e.target;
    if (id === "src" && files && files.length > 0) {
      const img = "https://bigfurnitureshow.com/assets/missingImage.ea9d6c9f.jpg";
      setBanner((prevBanner) => ({ ...prevBanner, src: img }));
    } else {
      setBanner((prevBanner) => ({ ...prevBanner, [id]: value }));
    }
  };

  const send = () => {
    setCalling(true);
    setTimeout(() => {
      console.log("SEND TO API:", banner);
      setCalling(false);
      alert("SUCCESSFUL SAVE (TODO: improve)");
    }, 700);
  };

  return (
    <TabContainerHOC
      commingSoon
      title="Banner 2, Image and Texts"
      description={<Description />}
      containerClass="banner-footer"
      loading={calling}
    >
      <div className="row mt-5">
        <div className="col-sm col-left">
          <h2>Add Texts</h2>
          <p><b>Add Primary Text</b></p>
          <p>Recommended 40 - 60 characters approximately</p>
          <input
            id="primaryText"
            onChange={handleChange}
            value={banner.primaryText}
            type="text"
            className="input-round form-control mt-2"
            placeholder="Type text."
          />

          <p className="mt-4"><b>Add Secondary Text</b></p>
          <p>Recommended 80 - 120 characters approximately</p>
          <input
            id="secondaryText"
            onChange={handleChange}
            value={banner.secondaryText}
            type="text"
            className="bigI input-round form-control mt-2"
            placeholder="Type text."
          />

          <p className="mt-4"><b>Shop Now Button</b><span className="esl">Insert Link</span></p>
          <input
            id="url"
            onChange={handleChange}
            value={banner.url}
            type="text"
            className="input-round form-control mt-2 link"
            placeholder="https://"
          />
        </div>

        <div className="col-sm col-right">
          <h2>Add Image</h2>
          <div className="headerDescription">
            <p><b>Image Desktop</b></p>
            <p>Image size 1400 x 215 pixels</p>
          </div>
          <div className="container up mt-2">
            <div className="avatar-upload">
              <div className="avatar-edit">
                <input
                  onChange={handleChange}
                  type="file"
                  id="src"
                  accept=".png, .jpg, .jpeg"
                />
                <label htmlFor="src"></label>
              </div>
              <div className="avatar-preview">
                {banner.src ? (
                  <div
                    id="imagePreview"
                    style={{
                      backgroundImage: `url(${banner.src})`,
                      backgroundSize: "cover",
                    }}
                  />
                ) : (
                  <div
                    id="imagePreview"
                    style={{ backgroundImage: `url(${DemoImage})` }}
                  />
                )}
              </div>
            </div>
            <h4>Upload Photo</h4>
          </div>
        </div>
      </div>

      <div className="navigationFooter mt-5">
        <a className="backBtn" href={nav}>BACK</a>
        <span className="saveBtn" onClick={send}>SAVE</span>
      </div>
    </TabContainerHOC>
  );
};

const Description: React.FC = () => (
  <ul>
    <li>Add the Image, Primary text, Secondary text, Subtitle, and Link.</li>
  </ul>
);

export default BannerFooterPage;